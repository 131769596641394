import React from "react"
import styled from "styled-components"
import { H3, H2 } from "../theme"
import GridComponent from "../GridComponent"
import Form from "../Form"
import S1 from "../../images/phone2.png"
import S2 from "../../images/google-play-badge.png"

const Container = styled.div`
  position: relative;
`

const TreningBackSection = styled.div`
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #141212;
`

const ContactContainer = styled.div`
  z-index: 100;
  width: 100%;
  height: 100%;
  z-index: 4;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding-top: 50px; */
  padding-bottom: 100px;
  @media screen and (max-width: 1200px) {
    padding-bottom: 50px;
    flex-direction: column;
  }
`

const FormContainer = styled.div`
  z-index: 5;
  width: 50%;
  /* padding-left: 160px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    color: white !important;
  }
  img {
    width: 200px;
  }
  @media screen and (max-width: 1200px) {
    order: 1;
    width: 100%;
    margin-top: 140px;
    h1 {
      margin-bottom: 100px;
    }
  }
  @media screen and (max-width: 600px) {
    margin-top: 100px;
    h1 {
      text-align: center;
    }
  }
`
const AppContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1200px) {
    width: 100%;
    display: block;
    text-align: center;
    order: 2;
    margin-top: 100px;
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    display: block;
    text-align: center;
    order: 2;
    margin-top: 100px;
  }
  h1 {
    font-size: 30px;
  }
`

const FormSection = () => {
  return (
    <Container id="contact">
      <GridComponent />
      <TreningBackSection />
      <ContactContainer>
        <AppContainer>
          {/* <H2>NAŠA APLIKACIJA</H2> */}
          <img src={S1} alt="" />
          <H3>Preuzmite Našu Fast Fit aplikaciju za klijente</H3>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=rs.fastfit.app&hl=en"
          >
            <img src={S2} alt="" className="downloadImg" />
          </a>
        </AppContainer>

        <FormContainer>
          <Form />
        </FormContainer>
      </ContactContainer>
    </Container>
  )
}

export default FormSection
