import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeSection from "../components/sections/HomeSections"
import ContactSection from "../components/sections/ContactSection"
import FormSection from "../components/sections/FormSection"

const Kontakt = () => (
  <Layout>
    <SEO title="Kontakt" />
    <HomeSection />
    <FormSection />
  </Layout>
)

export default Kontakt
